import './App.css';
import ComingSoonComponent from './ComingSoon';

function App() {
  return (
    <>
      <ComingSoonComponent />
    </>
  );
}

export default App;
